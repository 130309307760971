import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
import { MdArrowDropDown, MdArrowDropUp, MdClose, MdMenu } from 'react-icons/md';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MyContext } from '../../context';
import { useProjectAssets } from '../../projects/journey-trade/indexPage';

const Header = () => {
  const { languages, originalPath, language } = useI18next();
  const { project, isJourneyTradePage, isAtanerPage, isSmithyPage } = useContext(MyContext);
  // eslint-disable-next-line no-nested-ternary
  const transNamespace = isJourneyTradePage ? 'journey-trade' : isAtanerPage ? 'ataner' : 'smithy';
  const { t } = useTranslation(transNamespace);
  const assets = useProjectAssets();
  const projectIsMultiLang = project.isMultiLang;
  const [showLangs, setShowLangs] = useState(false);
  const [showRespoMenu, setShowRespoMenu] = useState(false);
  const langRef = useRef(null);
  const logoImage = getImage(assets.find((asset) => asset.name === 'logo'));

  useEffect(() => {
    const handleClickOutsideLang = (event) => {
      if (langRef.current && !langRef.current.contains(event.target)) {
        setShowLangs(false);
      }
    };
    document.addEventListener('click', handleClickOutsideLang, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideLang, true);
    };
  }, []);

  const headerHeightClass = isAtanerPage ? 'md:h-40 h-24' : 'h-24';
  return (
    <header className={`sticky top-0 bg-background shadow ${headerHeightClass} z-10`}>
      <div className="md:container flex justify-between items-stretch md:mx-auto mx-0 px-5 h-full">
        <div className="flex items-stretch text-2xl">
          <AnchorLink
            href="#home"
            className="mr-3 flex items-center xl:text-4xl sm:text-3xl text-2xl font-semibold text-primary"
            offset="96"
          >
            {isJourneyTradePage && (
              <div className="flex items-center">
                <span>{project.info.web.title}</span>
                <GatsbyImage
                  image={logoImage}
                  alt={project.info.meta.title}
                  objectFit="contain"
                  className="ml-4 w-28 h-full"
                  loading="eager"
                />
              </div>
            )}
            {isAtanerPage && (
              <GatsbyImage
                image={logoImage}
                alt={project.info.meta.title}
                objectFit="contain"
                className="md:w-96 w-48 h-full"
                loading="eager"
              />
            )}
            {isSmithyPage && <h1>{project.info.web.title}</h1>}
          </AnchorLink>
        </div>
        <div
          className="flex items-stretch md:px-0 px-6 md:py-0 py-4 md:static absolute md:flex-row flex-col md:bg-transparent bg-white md:shadow-none shadow right-0"
          style={{ top: showRespoMenu ? '100%' : '-1000%' }}
        >
          <div className="flex items-stretch md:flex-row flex-col md:space-y-0 space-y-3 ">
            {project.pages.map((page) => (
              <AnchorLink
                className="md:px-3 flex items-center md:justify-center justify-end text-primary text-lg"
                href={`#${page}`}
                key={page}
                offset="96"
              >
                <span>{t(`sections.${page}.menuTitle`)}</span>
              </AnchorLink>
            ))}
            {projectIsMultiLang && (
              <div
                className="relative flex items-stretch md:justify-stretch justify-end"
                ref={langRef}
              >
                <a
                  className="cursor-pointer flex-row items-center pl-3 md:justify-center justify-end md:flex hidden text-primary"
                  onClick={() => setShowLangs((prev) => !prev)}
                  onKeyDown={() => setShowLangs((prev) => !prev)}
                  role="button"
                  tabIndex={0}
                  title="Language selector"
                >
                  {language.toUpperCase()}{' '}
                  <div className="">{showLangs ? <MdArrowDropUp /> : <MdArrowDropDown />}</div>
                </a>
                {(showLangs || showRespoMenu) && (
                  <ul
                    className="languages md:absolute static md:bg-white bg-transparent w-full flex flex-col md:shadow shadow-none md:space-y-0 space-y-3"
                    style={{ top: '100%' }}
                  >
                    {languages.map((lng) => (
                      <li key={lng} className="flex md:text-center text-right text-primary">
                        <Link
                          to={originalPath}
                          language={lng}
                          className="md:py-2 w-full"
                          onClick={() => setShowLangs((prev) => !prev)}
                          onKeyDown={() => setShowLangs((prev) => !prev)}
                        >
                          {lng.toUpperCase()}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        <a
          className="cursor-pointer flex-row items-center md:hidden flex text-3xl text-primary"
          onClick={() => setShowRespoMenu((prev) => !prev)}
          onKeyDown={() => setShowRespoMenu((prev) => !prev)}
          role="button"
          tabIndex={0}
          title="Mobile menu button"
        >
          {!showRespoMenu ? <MdMenu /> : <MdClose />}
        </a>
      </div>
    </header>
  );
};

export default Header;
