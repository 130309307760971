import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal/lib/components/Modal';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Header from '../components/layout/Header';
import { MyContext } from '../context';

Modal.setAppElement(`#___gatsby`);

const Index = () => {
  const { language } = useI18next();
  const ctx = useContext(MyContext);
  const IndexPage = ctx.project.indexPage;
  const Footer = ctx.project.footerComp;
  const { isJourneyTradePage } = ctx;
  const { title, description, host } = ctx.project.info.meta;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
      >
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" hrefLang="sk" href={host} />
        {isJourneyTradePage && <link rel="alternate" hrefLang="it" href={`${host}/it`} />}
        {isJourneyTradePage && <link rel="alternate" hrefLang="en" href={`${host}/en`} />}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Header />
      <IndexPage />
      <Footer />
    </>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
